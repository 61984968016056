<script setup lang="ts">
import {ref, useSweetAlert} from '#imports';
import InputUnit from "~/components/units/input-unit.vue";
import ButtonUnit from "~/components/units/button-unit.vue";
import type {Ref} from "vue";
import type {ILogin} from "~/interfaces/auth/ILogin";
import {useReCaptcha} from "vue-recaptcha-v3";
import {useRouter} from "#app";
import useAuthStatement from "~/composables/use-auth-state";

const {customErrorAlert} = useSweetAlert();

const {$api} = useNuxtApp();
const router = useRouter();
const recaptchaInstance = useReCaptcha();
const {setToken} = useAuthStatement();
const loading = ref(false);
const form: Ref<ILogin> = ref({
	email: '',
	password: '',
	reCaptchaToken: '',
});

const recaptcha = async (action: string) => {
	await recaptchaInstance?.recaptchaLoaded();
	return recaptchaInstance?.executeRecaptcha(action);
};

const login = async (): Promise<void> => {

	if (form.value.email.length === 0 || form.value.password.length === 0) {
		return;
	}
	form.value.reCaptchaToken = await recaptcha('login');
	loading.value = true;

	const {data: response, pending, error} = await $api.auth.postLogin(form.value);
	if (response.value) {
		loading.value = false;
		setToken(response.value.item.token);
		if (response.value.item.token) {
			await router.push({path: '/'});
		}
	}

	if (error.value) {
		customErrorAlert('Sikertelen bejelentkezés', error.value?.data?.message ?? '');
	}
	loading.value = false;

}
</script>

<template>
	<div class="logged-out-box__logo text-center">
		<img src="assets/images/nuxt.png" alt="Adminfelület logo" width="260" class="mb-4"/>
	</div>

	<form @keydown.enter.prevent="login()">
		<input-unit
				:options="{
						type: 'email',
						placeholder: 'E-mail',
						name: 'Email cím',
						slug:'email',
						class: 'mb-3',
						required: true
					}"
				v-model="form.email"
		/>
		<input-unit
				:options="{
						type: 'password',
						placeholder: 'Jelszó',
						name: 'Jelszó',
						slug:'password',
						autocomplete:'current-password',
						class: 'mb-3',
						required: true
					}"
				v-model="form.password"
		/>

		<button-unit
				class="btn btn--primary btn--rounded w-100"
				:options="{
						type: 'submit',
						color: 'success',
						text: 'Bejelentkezés',
						disabled: loading
					}"
				@click-on-btn="login()"
		/>
	</form>
</template>

<style scoped>

</style>
