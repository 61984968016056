<script setup lang="ts">
import {definePageMeta, useHead, useRuntimeConfig} from '#imports';
import LoginBox from "~/components/layouts/login-box.vue";

const config = useRuntimeConfig();
useHead({title: config?.public?.title + ' - Bejelentkezés'});
definePageMeta({name: 'Login', layout: 'logged-out'});

</script>

<template>
	<div class="logged-out-box">
		<login-box/>
	</div>
</template>

<style scoped>

</style>

